import React from 'react'
import Hero from '../components/Hero'
import Layout from '../layouts/index'
import styles from './index.module.css'

import FeatureFeedback from '../components/FeatureFeedback'
import Integrations from '../components/Integrations'
import FeaturesList from '../components/FeaturesList'
import FeatureRoadmap from '../components/FeatureRoadmap'
import FeatureChangelog from '../components/FeatureChangelog'
import SignupButton from '../components/SignupButton'

import clients1 from '../../assets/clients.png'
//import clients2 from '../../assets/clients_colour.png'

const IndexPage = () => (
  <Layout>
    <div className={styles.root}>
      <div className={styles.section}>
        <Hero />
      </div>

      <div className={styles.section}>
        <div className={styles.clientsWrap}>
          <div className={styles.title}>Trusted by</div>
          <div className={styles.clients}>
            <img className={styles.clients} src={clients1} />
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <FeatureFeedback />
        <FeaturesList />
      </div>

      <div className={styles.section}>
        <FeatureRoadmap />
      </div>

      <div className={styles.section}>
        <FeatureChangelog />
      </div>

      <div className={styles.section}>
        <Integrations />
      </div>

      <div className={styles.section}>
        <SignupButton />
      </div>
    </div>
  </Layout>
)

export default IndexPage
