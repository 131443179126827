import React from 'react'
import styles from './Hero.module.css'
import classNames from 'classnames'
import styled from "styled-components"

import SignupButton from '../components/SignupButton'
import arrow from "../../assets/arrow.png"

const Arrow = styled.div`
  position: absolute;
  right: -20px;
  top: 80px;
  > img {
    width: 65px;
  }
`
const Container = styled.div`
  position: relative;
  display: inline-block;
`
const DemoLink = styled.a`
  font-size: 1rem;
  text-decoration: underline;
`

const Hero = () => (
  <div className={styles.root}>
    <div className={styles.content}>
      <h1 className={styles.title}>
        Collect feature requests for your product
      </h1>
      <p className={styles.subtitle}>
        <span className={styles.tag}>Centralise</span>,{' '}
        <span className={classNames(styles.tag, styles.tagM)}>manage</span> and{' '}
        <span className={classNames(styles.tag, styles.tagP)}>prioritise</span>{' '}
        customer feedback.
      </p>
      <p className={styles.subtitle}>
        Build things your users will love
      </p>
      <Container>
        <SignupButton />
        <DemoLink target="_blank" href="https://feedback.crowdlever.io">See demo feedback board</DemoLink>
        <Arrow>
          <img src={arrow} />
        </Arrow>
      </Container>
    </div>
  </div>
)

export default Hero
