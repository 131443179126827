import React from 'react'
import styles from './Feature.module.css'

import Chrome from '../components/Chrome'
import image from '../../assets/roadmap-portal-large.png'

export const FeatureRoadmap = () => {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <h2>A roadmap to excite users</h2>
        <p>Show your users what's coming next..</p>
        <Chrome>
          <img src={image} />
        </Chrome>
      </div>
    </div>
  )
}

export default FeatureRoadmap
