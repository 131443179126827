import React from 'react'
import styles from './Feature.module.css'

import Chrome from '../components/Chrome'
import image from '../../assets/feedback-portal-large.png'

export const FeatureFeedback = () => {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <h2>Your product's feedback portal</h2>
        <p>
          Collect feedback from your users, internally from your team, or from
          specific stakeholders.
        </p>
        <Chrome>
          <img src={image} />
        </Chrome>
      </div>
    </div>
  )
}

export default FeatureFeedback
