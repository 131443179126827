import React from 'react'
import styles from './FeaturesList.module.css'

const feedbackProductFeatures = [
  {
    title: 'Your brand',
    description:
      'Use your own logo and brand colours to make your feedback portal your own.',
    icon: 'fa-fw fad fa-paint-roller',
  },
  {
    title: 'Your own domain',
    description:
      'Host your feedback portal on your own custom domain, e.g. feedback.yourapp.com.',
    icon: 'fa-fw fad fa-globe',
  },
  {
    title: 'Permissions',
    description:
      'Limit access to your feedback portal based on who you want to collect feedback from.',
    icon: 'fa-fw fad fa-eye-slash',
  },
  {
    title: 'Single Sign On',
    description:
      'Login your users automatically with our SSO implementation, or let your users login via Github, Faceboook or Twitter.',
    icon: 'fa-fw fad fa-id-badge',
  },
  {
    title: 'Invite your team',
    description:
      'Give access to your entire customer support team, or specific people.',
    icon: 'fa-fw fad fa-users-cog',
  },
  {
    title: '..and loads more',
    description:
      'Plus loads more features to make collecting, and managing customer feedback a breeze.',
    icon: 'fa-fw fad fa-rocket',
  },
]

const Feature = ({ feature }) => {
  return (
    <div className={styles.feature}>
      <div className={styles.icon}>
        <i className={feature.icon}></i>
      </div>
      <div className={styles.body}>
        <div className={styles.title}>{feature.title}</div>
        <div className={styles.description}>{feature.description}</div>
      </div>
    </div>
  )
}

export default class FeaturesList extends React.Component {
  render() {
    return (
      <div className={styles.root}>
        <div className={styles.content}>
          <div className={styles.list}>
            {feedbackProductFeatures.map((feature) => (
              <Feature feature={feature} />
            ))}
          </div>
        </div>
      </div>
    )
  }
}
