import React from 'react'
import { Link } from 'gatsby'
import styles from './Integrations.module.css'
import slackLogo from '../../assets/integrations/slack.png'
import intercomLogo from '../../assets/integrations/intercom.png'
import zapierLogo from '../../assets/integrations/zapier.png'

const Integrations = () => {
  return (
    <div className={styles.root}>
      <h2>Connect with your favourite tools</h2>
      <p className={styles.subtitle}>
        We provide integrations to make it easy for you to collect feedback
        through other channels
      </p>
      <div className={styles.integrations}>
        <div className={styles.integration}>
          <div className={styles.logo}>
            <img alt="Slack logo" src={slackLogo} />
          </div>
          <div className={styles.description}>
            <div className={styles.title}>
              <Link to={'/integrations/slack/'}>Slack</Link>
            </div>
            <p>
              Get instant notifications to your channel when you receive new
              feedback, comments or votes
            </p>
          </div>
        </div>
        <div className={styles.integration}>
          <div className={styles.logo}>
            <img alt="Intercom logo" src={intercomLogo} />
          </div>
          <div className={styles.description}>
            <div className={styles.title}>
              <Link to={'/integrations/intercom/'}>Intercom</Link>
            </div>
            <p>
              Capture feature requests while talking to your customers on
              Intercom. Allow your users to add feedback directly in Intercom.
            </p>
          </div>
        </div>
        <div className={styles.integration}>
          <div className={styles.logo}>
            <img alt="Zapier logo" src={zapierLogo} />
          </div>
          <div className={styles.description}>
            <div className={styles.title}>Zapier</div>
            <p>
              We got Zapier triggers for new feedback, new comments, votes and
              status changes on feedback. Integrate Suggested with your existing
              workflow.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Integrations
