import React from 'react'
import styles from './Feature.module.css'

import Chrome from '../components/Chrome'
import image from '../../assets/changelog-portal-large.png'

export const FeatureChangelog = () => {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <h2>Announce product updates</h2>
        <p>
          You've shipped a new feature, fixed bugs, or made improvements!
          Announce this to your users with product changelog.
        </p>
        <Chrome>
          <img src={image} />
        </Chrome>
      </div>
    </div>
  )
}

export default FeatureChangelog
